// const base = 'http://localhost:3000/api';
// const baseNoApi = 'http://localhost:3000';
// const base = 'https://api.olivos.moonideas.com/api';
// const baseNoApi = 'https://api.olivos.moonideas.com';
const base = "https://api.losolivos.com.uy/api";
const baseNoApi = "https://api.losolivos.com.uy";

const endpoints = {
  baseNoApi,
  highlights: `${base}/highlights`,
  brands: `${base}/brands`,
  contactMessages: `${base}/contact-messages`,
};

export default endpoints;
