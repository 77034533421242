/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useEffect } from 'react';
import Slider from 'react-slick';
import endpoints from '../config/endpoint';
import { getBrandsForBarrio } from '../service/BrandsService';
import '../style/components/clients.css';

function Clients({barrioId}) {
  const [brands, setBrands] = useState([]);

  const getBrands = async () => {
    const brandsResponse = await getBrandsForBarrio(barrioId);
    if (brandsResponse.data) setBrands(brandsResponse.data);
  };

  useEffect(() => {
    console.log('BRANDS', brands);
  }, [brands]);

  useEffect(() => {
    getBrands();
  }, []);

  const clients = {
    dots: false,
    infinite: true,
    slidesToShow: Math.min(brands.length - 1, 6),
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: '130px',
    centerMode: true,
    speed: 1000,
    autoplaySpeed: 2000,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(brands.length - 1, 4),
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: Math.min(brands.length - 1, 4),
          centerPadding: '50px',
          autoplay: true,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: Math.min(brands.length - 1, 2),
          centerPadding: '70px',
          centerMode: true,
          autoplay: true,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className='containerClients'>
      <Slider {...clients}>
        {brands.map((brand) => {
          const fileUrl = `${endpoints.baseNoApi}${brand.file.url}`;
          return <img src={fileUrl} alt='Clients' />;
        })}
      </Slider>
    </div>
  );
}

export default Clients;
