const darkStyle = [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 100,
        },
        {
          color: '#888888',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#888888',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#F1F1F1',
        },
        {
          lightness: 0,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#F1F1F1',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#DFDFDF',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#DFDFDF',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#F1F1F1',
        },
        {
          lightness: 0,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FEFEFE',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FEFEFE',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#FEFEFE',
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#DFDFDF',
        },
        {
          lightness: 0,
        },
      ],
    },
  
  ];
  
  export default { darkStyle };
  