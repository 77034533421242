import { React } from "react";
import { Link } from "react-router-dom";
import "../style/site/landingSelection.css";
import { motion } from "framer-motion";
import { LogoPolo, LogoHorneros } from "../img/img";

function LandingSelection() {
  return (
    <div className="landingSelection">
      <Link
        to="/barrio-polo"
        className="selectLanding backgroundPolo"
        target="_blank"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 2,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <img src={LogoPolo} alt="Logo Los Olivos barrio polo" />
        </motion.div>
      </Link>
      <Link
        to="/barrio-horneros"
        className="selectLanding backgroundHorneros"
        target="_blank"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 2,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <img src={LogoHorneros} alt="Logo Los Olivos de los horneros" />
        </motion.div>
      </Link>
    </div>
  );
}

export default LandingSelection;
