import { React, useEffect, useState } from 'react';
import Header from '../components/Header';
import Frase from '../components/Frase';
import Information from '../components/Infomation';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Clients from '../components/Clients';
import DarkStyle from '../components/stylesMap';
import GoogleMapReact from 'google-map-react';
import ScrollTriger from 'react-scroll-trigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import CountUp from 'react-countup';
import ScrollIntoView from 'react-scroll-into-view';
import Map from '../components/Map';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../style/site/barrioPolo.css';
import {
  LogoPolo,
  OPoloDarkGreen,
  ArrowSliderLeft,
  ArrowSliderRight,
  SiteOne,
  SiteTwo,
  SiteThree,
  SiteFour,
  IconUno,
  IconDos,
  IconTres,
  IconCuatro,
  IconCinco,
  IconSeis,
  IconSiete,
  IconOcho,
  IconNueve,
  StickerPolo,
  ImgSectionFourPolo,
  OPoloLightGreen,
  BackgroundBarrioPoloContact,
  BackgroundLogoPoloContact,
  PortadaPolo,
  PortadaPoloDos,
  PortadaPoloTres,
  PortadaPoloMobile,
  PortadaPoloDosMobile,
  PortadaPoloTresMobile,
} from '../img/img';
import { getHighlightsForBarrio } from '../service/HighlightsService';
import endpoints from '../config/endpoint';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <button onClick={onClick} className='arrowSliderRight' type='button'>
      <img src={ArrowSliderRight} alt='' />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <button onClick={onClick} className='arrowSliderLeft' type='button'>
      <img src={ArrowSliderLeft} alt='' />
    </button>
  );
}

function BarrioPolo() {
  const [highlights, setHighlights] = useState([]);

  const getHighlights = async () => {
    const highlightsResponse = await getHighlightsForBarrio(1);
    if (highlightsResponse.data) setHighlights(highlightsResponse.data);
  };
  useEffect(() => {
    getHighlights();
  }, []);

  const Marker = (props) => {
    return <div className='SuperAwesomePin'></div>;
  };

  const sliderPolo = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 3,
    centerPadding: '400px',
    centerMode: true,
    responsive: [
      {
        breakpoint: 820,
        settings: {
          dots: false,
          nextArrow: false,
          prevArrow: false,
          infinite: true,
          centerPadding: '40px',
          speed: 1000,
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderPortada = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderDatos = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dots: false,
    nextArrow: false,
    autoplay: true,
    prevArrow: false,
    slidesToScroll: 3,
    centerPadding: '100px',
    centerMode: true,
  };

  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='barrioPolo'>
      <div className='heroBackground'>
        <Header
          logo={LogoPolo}
          linkHeaderOne='LOS OLIVOS'
          linkHeaderTwo=<ScrollIntoView selector='#caracteristicas'>
            CARÁCTERISTICAS
          </ScrollIntoView>
          linkHeaderThree=<ScrollIntoView selector='#ubicacionPlano'>
            UBICACIÓN Y PLANO
          </ScrollIntoView>
          linkHeaderFour=<ScrollIntoView selector='#faq'>FAQ</ScrollIntoView>
          linkHeaderFive=<ScrollIntoView selector='#contacto'>
            CONTACTO
          </ScrollIntoView>
          linkHeaderSix=<ScrollIntoView selector='#equipo'>
            EQUIPO
          </ScrollIntoView>
        />
        <Slider {...sliderPortada}>
          <div className='imgSliderPortada desktopElement'>
            <img src={PortadaPolo} alt='Foto del barrio' />
          </div>
          <div className='imgSliderPortada desktopElement'>
            <img src={PortadaPoloDos} alt='Foto del barrio' />
          </div>
          <div className='imgSliderPortada desktopElement'>
            <img src={PortadaPoloTres} alt='Foto del barrio' />
          </div>
        </Slider>
        <Slider {...sliderPortada}>
          <div className='imgSliderPortada mobileElement'>
            <img src={PortadaPoloMobile} alt='Foto del barrio' />
          </div>
          <div className='imgSliderPortada mobileElement'>
            <img src={PortadaPoloDosMobile} alt='Foto del barrio' />
          </div>
          <div className='imgSliderPortada mobileElement'>
            <img src={PortadaPoloTresMobile} alt='Foto del barrio' />
          </div>
        </Slider>
      </div>
      <div id='caracteristicas' />
      <section className='section-one'>
        <div className='container-section-one'>
          <img
            src={OPoloDarkGreen}
            alt='Logo Barrio Polo con imagen de fondo'
            data-aos='fade-right'
            data-aos-duration='2500'
          />
          <div className='information-section-one'>
            <h2 data-aos='fade-up' data-aos-duration='1000'>
              Despertar en <br />{' '}
              <span className='colorLightGreen'>un barrio jardín</span>
            </h2>
            <h4 data-aos='fade-up' data-aos-duration='2000'>
              Respirar el aire fresco de la mañana, regar las flores, andar en
              bicicleta o caminar en el verde por veredas arboladas es posible 
              otra vez en Carrasco.
            </h4>
            <p data-aos='fade-up' data-aos-duration='3000'>
              En los Olivos podrás disfrutar del estilo de vida que siempre
              soñaste, en un lugar que tendrá la mejor valorización, combinando
              calidad de vida con inversión segura. Vivir el presente pero
              también pensar en el futuro es, sin lugar a dudas, tu mejor
              inversión.
            </p>
          </div>
        </div>
      </section>
      <Frase frase='El lugar que siempre soñaste' />
      <section className='section-two'>
        <Slider {...sliderPolo}>
          {highlights.map((highlight) => {
            const fileUrl = `${endpoints.baseNoApi}${highlight.file.url}`;
            return (
              <div className='containerImgSlider'>
                <img src={fileUrl} alt='Imagen Polo' />
              </div>
            );
          })}
        </Slider>
      </section>
      <section className='section-three mobileElement'>
        <Slider {...sliderDatos}>
          <div className='number'>
            <h2>250</h2>
            <h2>lotes</h2>
          </div>
          <div className='number'>
            <h2>25</h2>
            <h2>hectáreas</h2>
          </div>
          <div className='number'>
            <h2>+4000</h2>
            <h2>árboles</h2>
          </div>
          <div className='number'>
            <h2>25'</h2>
            <h2>al centro</h2>
          </div>
          <div className='number'>
            <h2>250</h2>
            <h2>lotes</h2>
          </div>
          <div className='number'>
            <h2>25</h2>
            <h2>hectáreas</h2>
          </div>
          <div className='number'>
            <h2>+4000</h2>
            <h2>árboles</h2>
          </div>
          <div className='number'>
            <h2>25'</h2>
            <h2>al centro</h2>
          </div>
        </Slider>
      </section>
      <section className='section-three desktopElement'>
        <div className='numbers-barrio-polo'>
          <div className='number' data-aos='fade-left' data-aos-duration='1000'>
            <h2>
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp end={25} duration={3} />}
              </ScrollTriger>
            </h2>
            <h2>hectáreas</h2>
          </div>
          <div className='number' data-aos='fade-left' data-aos-duration='1500'>
            <h2>
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp start={210} end={250} duration={3} />}
              </ScrollTriger>
            </h2>
            <h2>lotes</h2>
          </div>
          <div className='number' data-aos='fade-left' data-aos-duration='2000'>
            <h2>
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp prefix='+' start={3950} end={4000} duration={3} />
                )}
              </ScrollTriger>
            </h2>
            <h2>árboles</h2>
          </div>
          <div className='number' data-aos='fade-left' data-aos-duration='2500'>
            <h2>
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp end={25} duration={3} suffix="'" />}
              </ScrollTriger>
            </h2>
            <h2>al centro</h2>
          </div>
        </div>
      </section>
      <section className='section-four'>
        <div className='site-polo site-green'>
          <img src={SiteOne} alt='Barrio Polo' className='mobileElement' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Aire Libre
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              <strong>
                Un lugar silencioso que se prepara para ser el hogar que siempre
                soñaste
              </strong>
              , calles arboladas, generosos jardínes y aire puro para volver a
              vivir con gusto los pequeños placeres de la vida: encender la
              estufa a leña en el invierno, y en el verano disfrutar el
              parrillero con una cena en familia, bajo las estrellas.
              <br></br>
              <br></br>
              <strong>
                Proyectar un futuro en la amplitud y pureza de un lugar pensado
                por ti, para ti y los tuyos.
              </strong>
            </p>
          </div>
          <img src={SiteOne} alt='Barrio Polo' className='desktopElement' />
        </div>
        <div className='site-polo site-white'>
          <img src={SiteTwo} alt='Barrio Polo' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Estilo
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              El hogar de tus sueños es posible. Una casa , hecha para ti de
              acuerdo con tus gustos, y las necesidades de tu vida y las de tu
              familia.
            </p>
            <p data-aos='fade-up' data-aos-duration='2500'>
              La armonioso distribución de los espacios, concebida en el diseño
              original del barrio, está pensada para distrutar de todos los
              encantos del entorno. Y, si se admite que el medio tiene una
              fuerte influencia en nuestro carácter, entonces la vida en este
              lugar promete el aire más apacible.
            </p>
          </div>
        </div>
        <div className='site-polo site-green'>
          <img src={SiteThree} alt='Barrio Polo' className='mobileElement' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Servicios
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              Seguro, cerca de todo, y a la vez tan lejos del ruido y el estrés.
            </p>
            <p data-aos='fade-up' data-aos-duration='2500'>
              Al alcance de una amplia gama de servicios, junto al Carrasco Polo
              Club, con un acceso privilegiado a su campo de polo y equitación,
              piscina, salas de gimnasia y musculación, y a sus canchas, donde
              practicar diversas actividades deportivas. A un paso de la zona de
              colegios más exclusivos: junto al St. Patrick´s y a sólo segundos
              del British Schools, Uruguayan American School, Anglo, Woodlands,
              Jesús maría y Christian Brothers, por nombrar sólo algunos. A muy
              pocas cuadras de importantes centros de compras y servicios de
              primer nivel: Devoto Fresh Market, Portones Shopping y
              Moviecenter. A unos minutos de la rambla, la playa y el mar; a 5
              minutos del aeropuerto internacional y a 25’’ del centro
              montevideano.
            </p>
          </div>
          <img src={SiteThree} alt='Barrio Polo' className='desktopElement' />
        </div>
        <div className='site-polo site-white'>
          <img src={SiteFour} alt='Barrio Polo' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Valorización
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              LOS OLIVOS se construye en un lugar privilegiado, en la zona con
              más proyección de crecimiento del este montevideano. Este proyecto
              nace como natural continuación de Carrasco, reviviendo el espíritu
              del lugar, elegante y plácido, que hace casi cien años nacía y se
              conocía como Balneario Carrasco.
            </p>
            <p data-aos='fade-up' data-aos-duration='2500'>
              Como aquellos emprendimientos pioneros de antaño, tu terreno
              tendrá la mayor valorización, y tu futura residencia, en el
              corazón del nuevo barrio, será una inversión segura, con una
              enorme proyección hacia el futuro.
            </p>
          </div>
        </div>
      </section>
      <Frase frase='Construí tu futuro en Los Olivos' />
      <section className='section-five'>
        <img
          src={ImgSectionFourPolo}
          alt='Imagen Paisaje Barrio polo'
          className='img-section-fou-polo mobileElement'
        />
        <div
          className='data-polo'
          data-aos='fade-right'
          data-aos-duration='1500'
        >
          <img src={StickerPolo} alt='Sticker Polo' className='stickerPolo' />
          <div className='data'>
            <img src={IconUno} alt='Icono' />
            <p>28 Hectareas</p>
          </div>
          <div className='data'>
            <img src={IconDos} alt='Icono' />
            <p>Frentes desde 18 a 30 m</p>
          </div>
          <div className='data'>
            <img src={IconTres} alt='Icono' />
            <p>250 lotes desde 650m2 a 1.000 m2</p>
          </div>
          <div className='data'>
            <img src={IconCuatro} alt='Icono' />
            <p>Seguridad</p>
          </div>
          <div className='data'>
            <img src={IconCinco} alt='Icono' />
            <p>Contiguo al Carrasco Polo Club</p>
          </div>
          <div className='data'>
            <img src={IconSeis} alt='Icono' />
            <p>En plena zona de colegios</p>
          </div>
          <div className='data'>
            <img src={IconSiete} alt='Icono' />
            <p>4.000 árboles de más de 20 especies</p>
          </div>
          <div className='data'>
            <img src={IconOcho} alt='Icono' />
            <p>Pegado al St. Patrick´s</p>
          </div>
          <div className='data'>
            <img src={IconNueve} alt='Icono' />
            <p>6 hectáreas de calles asfaltadas y áreas verdes</p>
          </div>
        </div>
        <img
          src={ImgSectionFourPolo}
          alt='Imagen Paisaje Barrio polo'
          className='img-section-fou-polo desktopElement'
          data-aos='fade-left'
          data-aos-duration='1500'
        />
      </section>
      <div id='ubicacionPlano' />
      <div className='ratio ratio-16x9'>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/Z96-ZW8zmH4'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
      <Frase frase='Cerca de todo, y a la vez lejos del ruido y el estrés' />
      <Map
        map=<GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDYJJGEsIbe3lEncnvtzddNuBvS_9vvCBk',
            language: 'es',
          }}
          style={{ height: '100%', width: '100%' }}
          options={{ styles: DarkStyle.darkStyle, gestureHandling: 'greedy', scrollwheel: false }}
          defaultZoom={15}
          center={{
            lat: -34.86229,
            lng: -56.06882,
          }}
        >
          <Marker lat={-34.86229} lng={-56.06882} />
        </GoogleMapReact>
      />
      <div id='faq' />
      <Information
        ImgInformation={OPoloLightGreen}
        queEsOlivos='LOS OLIVOS es un Barrio Jardín ubicado en la zona norte de Carrasco y que cuenta con 250 lotes para construir viviendas. No es un barrio privado ya que la normativa municipal no permite construir barrios privados dentro de Montevideo, por lo que todas las calles y espacios verdes fueron cedidos a la Intendencia Municipal de Montevideo para el uso público.'
        titleOfrece='Los servicios ofrecidos por el barrio LOS OLIVOS son:'
        ofreceUno='Conexión a la red subterránea de UTE'
        ofreceDos='Conexión a la red subterránea de OSE'
        ofreceTres='Saneamiento'
        ofreceCuatro='Gas por cañería'
        ofreceCinco='Antel (teléfono y fibra óptica)'
        ofreceSeis='TV cable para cualquier compañía'
        ofreceSiete='Seguridad privada con CCTV'
        ofreceOcho='Convenio con el Carrasco Polo Club'
        seguridadOlivos='LOS OLIVOS tiene contratada a la empresa privada de seguridad “Sildan Trading”, la cual incluye guardias físicos dentro del predio y la respuesta de las alarmas de las casas particulares. Además el barrio cuenta con un Circuito Cerrado de Televisión (CCTV), el cual también es monitoreado por la guardia del barrio. La seguridad funciona las 24 horas del día y todos los días del año y la misma cuenta con garitas en todas las entradas para verificar quien entra y sale del barrio, como también con garitas adentro del mismo para ver la circulación de la gente. Los guardias de seguridad también hacen recorridas perimetrales las 24 horas y usan un “bastón de rondas” para que la empresa pueda corroborar sus recorridos.'
        construccionOlivos='Todas las personas que compran un lote tienen la obligación de terminar la construcción antes de los 3 años de entregado el lote. No se puede construir una casa pre fabricada ni por el régimen de propiedad horizontal y la superficie total de la misma deberá ser mayor a 150 m2, salvo para los lotes que están dentro de la zona 3A definida por la normativa municipal de la Intendencia Municipal de Montevideo que encontrarán debajo, para los cuales se permitirá diferentes tipos de construcciones. En cuanto al diseño y estilo de la casa no hay ninguna restricción y los planos no deberán ser aprobados por LOS OLIVOS. Todas las construcciones deberán cumplir con la normativa municipal la cual para LOS OLIVOS está reglamentada por el decreto Nº 29.471 del 17/05/2001 y sus correcciones introducidas por el decreto Nº 29.714 del 22/11/2001.'
      />
      <div id='contacto' />
      <Contact
        imgContact={BackgroundBarrioPoloContact}
        logoContact={BackgroundLogoPoloContact}
        selectLogoBarrio={LogoPolo}
        barrio="Polo"
      ></Contact>
      <div id='equipo' />
      <Frase frase='Un equipo que apunta a la excelencia siempre' />
      <Clients barrioId={1}/>
      <Footer
        logoFooter={LogoPolo}
        telefono='(+598) 2622 1333'
        direccion='Camino Gigantes 4221'
        email='info@losolivos.com.uy'
        linkInstagram='https://www.instagram.com/losolivoscarrasco/'
      />
    </div>
  );
}

export default BarrioPolo;
