import { React } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingSelection from "./site/LandingSelection";
import BarrioPolo from "./site/BarrioPolo";
import BarrioHorneros from "./site/BarrioHorneros";

/* Instalaciones ya realizadas
Slick 
React Router 
Frame Motion
Ant Design 
Google Map React
*/

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingSelection />} />
        <Route path="/barrio-horneros" element={<BarrioHorneros />} />
        <Route path="/barrio-polo" element={<BarrioPolo />} />
      </Routes>
    </div>
  );
}

export default App;
