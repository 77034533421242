import { React } from "react";
import "../style/components/contact.css";

function PopUp(props) {
  return (
    <div className="popup">
      <div className="container-popup">
        <img src={props.logoBarrio} alt="logo" />
        <p>Tu mensaje fue envíado correctamente.</p>
        <button onClick={() => props.handleClose(false)}
              type="button" className="button-popup">
          ACEPTAR
        </button>
      </div>
    </div>
  );
}

export default PopUp;
