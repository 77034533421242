/* eslint-disable camelcase */
import axios from 'axios';
import endpoints from '../config/endpoint';

const getHighlightsForBarrio = async (barrioId) => {
  try {
    const params = {
      filter: { neighbourhoodId: barrioId },
    };
    return (await axios.get(`${endpoints.highlights}`, { params }))
      .data;
  } catch (error) {
    return error;
  }
};

export { getHighlightsForBarrio };
