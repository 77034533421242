import { React, useState } from "react";
import { Link } from "react-router-dom";
import { Spin as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import "../style/components/header.css";

function Header(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{
        duration: 3,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <div className="container-header">
        <img src={props.logo} alt="logo" className="logo-header" />
        <div className="link-header desktopElement">
          <Link to="/">{props.linkHeaderOne}</Link>
          <Link to={props.routeLinkTwo}> {props.linkHeaderTwo}</Link>
          <Link to={props.routeLinkThree}>{props.linkHeaderThree}</Link>
          <Link to={props.routeLinkFour}>{props.linkHeaderFour}</Link>
          <Link to={props.routeLinkFive}>{props.linkHeaderFive}</Link>
          <Link to={props.routeLinkSix}>{props.linkHeaderSix}</Link>
        </div>
        <button
          onClick={() => {
            setOpen(!isOpen);
          }}
          className={
            isOpen === true
              ? "mobileElement openMenu buttonBurger"
              : "mobileElement noOpenMenu buttonBurger"
          }
          type="button"
        >
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            direction="right"
            easing="ease-in"
            color="#FFFFFF"
            size={25}
          />
        </button>
        <div className="link-header mobileElement">
          <Link to="/">{props.linkHeaderOne}</Link>
          <Link to={props.routeLinkTwo}> {props.linkHeaderTwo}</Link>
          <Link to={props.routeLinkThree}>{props.linkHeaderThree}</Link>
          <Link to={props.routeLinkFour}>{props.linkHeaderFour}</Link>
          <Link to={props.routeLinkFive}>{props.linkHeaderFive}</Link>
          <Link to={props.routeLinkSix}>{props.linkHeaderSix}</Link>
        </div>
      </div>
    </motion.header>
  );
}

export default Header;
