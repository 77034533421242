import { React, useEffect } from "react";
import "../style/components/frase.css";
import Slider from "react-slick";
import AOS from 'aos';

function Frase(props) {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
       <div className="frase" data-aos="fade-zoom" data-aos-duration="1000">
        <h2>{props.frase}</h2>
       </div>
    )
}

export default Frase;