import { React, useState } from "react";
import Header from "../components/Header";
import Frase from "../components/Frase";
import Information from "../components/Infomation";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Map from "../components/Map";
import DarkStyle from "../components/stylesMap";
import GoogleMapReact from "google-map-react";
import Slider from "react-slick";
import ScrollTriger from "react-scroll-trigger";
import CountUp from "react-countup";
import ScrollIntoView from "react-scroll-into-view";
import "../style/site/barrioHorneros.css";
import {
  LogoHorneros,
  BackgroundBarrioHornerosContact,
  BackgroundLogoHornerosContact,
  OHornerosLight,
  IconUnoHorneros,
  IconOchoHorneros,
  IconNueveHorneros,
  IconDosHorneros,
  IconTresHorneros,
  IconCuatroHorneros,
  IconCincoHorneros,
  IconSeisHorneros,
  IconSieteHorneros,
  ImgSectionFourHoneros,
  FotoHorneros,
  FotoroHornerosTwo,
  FotoroHornerosFour,
  FotoroHornerosThree,
  ClientOneHorneros,
  ClientTwoHorneros,
  ClientThreeHorneros,
  ClientFourHorneros,
} from "../img/img";
import endpoints from "../config/endpoint";
import { useEffect } from "react";
import { getHighlightsForBarrio } from "../service/HighlightsService";

const sliderPortada = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function BarrioHorneros(props) {
  const [highlights, setHighlights] = useState([]);

  const getHighlights = async () => {
    const highlightsResponse = await getHighlightsForBarrio(2);
    if (highlightsResponse.data) setHighlights(highlightsResponse.data);
  };

  useEffect(() => {
    getHighlights();
  }, []);

  const Marker = () => {
    return <div className="SuperAwesomePin"></div>;
  };
  const sliderDatos = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dots: false,
    nextArrow: false,
    autoplay: true,
    prevArrow: false,
    slidesToScroll: 3,
    centerPadding: "50px",
    centerMode: true,
  };
  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="barrioHorneros">
      <Header
        logo={LogoHorneros}
        linkHeaderOne="LOS OLIVOS"
        linkHeaderTwo=<ScrollIntoView selector="#caracteristicas">
          CARÁCTERISTICAS
        </ScrollIntoView>
        linkHeaderThree=<ScrollIntoView selector="#faq">FAQ</ScrollIntoView>
        linkHeaderFour=<ScrollIntoView selector="#ubicacionPlano">
          UBICACIÓN Y PLANO
        </ScrollIntoView>
        linkHeaderFive=<ScrollIntoView selector="#contacto">
          CONTACTO
        </ScrollIntoView>
        linkHeaderSix=<ScrollIntoView selector="#equipo">EQUIPO</ScrollIntoView>
      />
      <Slider {...sliderPortada}>
        {highlights.map((highlight) => {
          const fileUrl = `${endpoints.baseNoApi}${highlight.file.url}`;
          return (
            <div className="imgSliderPortada">
              <img src={fileUrl} alt="Foto del barrio" />
            </div>
          );
        })}
      </Slider>
      <div id="caracteristicas" />
      <Frase frase="Bienvenido a tu nuevo hogar en la naturaleza" />
      <section className="section-five">
        <img
          src={ImgSectionFourHoneros}
          alt="Imagen Paisaje Barrio polo"
          className="img-section-fou-polo"
        />
        <div className="data-horneros" id="caracteristicas">
          <h3>
            El hogar de tus <br /> sueños es posible
          </h3>
          <p>
            Contruí tu casa, hecha para ti de acuerdo con tus gustos, y las
            necesidades de tu vida y las de tu familia.
          </p>
          <div className="data">
            <img src={IconDosHorneros} alt="Icono" />
            <p>Piscina</p>
          </div>
          <div className="data">
            <img src={IconTresHorneros} alt="Icono" />
            <p>Cancha de Tennis y Paddle</p>
          </div>
          <div className="data">
            <img src={IconCuatroHorneros} alt="Icono" />
            <p>Lagos</p>
          </div>
          <div className="data">
            <img src={IconOchoHorneros} alt="Icono" />
            <p>Colegio</p>
          </div>
          <div className="data">
            <img src={IconNueveHorneros} alt="Icono" />
            <p>Juegos para niños</p>
          </div>
          <div className="data">
            <img src={IconUnoHorneros} alt="Icono" />
            <p>Club House</p>
          </div>
          <div className="data">
            <img src={IconCincoHorneros} alt="Icono" />
            <p>Locales Comerciales</p>
          </div>
          <div className="data">
            <img src={IconSeisHorneros} alt="Icono" />
            <p>Oficinas</p>
          </div>
          <div className="data">
            <img src={IconSieteHorneros} alt="Icono" />
            <p>Hotel Boutique</p>
          </div>
        </div>
      </section>
      <section className="section-three desktopElement">
        <div className="numbers-barrio-polo">
          <div className="number">
            <h2>
              {" "}
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp end={72} duration={5} />}
              </ScrollTriger>
            </h2>
            <h2>hectáreas de Parque</h2>
          </div>
          <div className="number">
            <h2>
              {" "}
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp start={380} end={480} duration={5} />}
              </ScrollTriger>
            </h2>
            <h2>lotes</h2>
          </div>
          <div className="number">
            <h2>
              {" "}
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp end={7} duration={5} />}
              </ScrollTriger>
            </h2>
            <h2>barrios privados</h2>
          </div>
          <div className="number">
            <h2>
              {" "}
              <ScrollTriger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && <CountUp start={3} end={20} duration={5} />}
              </ScrollTriger>
            </h2>
            <h2>edificos</h2>
          </div>
        </div>
      </section>
      <section className="section-three mobileElement">
        <Slider {...sliderDatos}>
          <div className="number">
            <h2>72</h2>
            <h2>hectáreas</h2>
          </div>
          <div className="number">
            <h2>480</h2>
            <h2>lotes</h2>
          </div>
          <div className="number">
            <h2>7</h2>
            <h2>barrios privados</h2>
          </div>
          <div className="number">
            <h2>20</h2>
            <h2>edificios</h2>
          </div>
          <div className="number">
            <h2>72</h2>
            <h2>hectáreas de Parque</h2>
          </div>
          <div className="number">
            <h2>480</h2>
            <h2>lotes</h2>
          </div>
          <div className="number">
            <h2>7</h2>
            <h2>barrios privados</h2>
          </div>
          <div className="number">
            <h2>20</h2>
            <h2>edificios</h2>
          </div>
        </Slider>
      </section>


      <section className='section-four'>
        <div className='site-polo site-brown'>
          <img src={FotoroHornerosThree} alt='Barrio Polo' className='mobileElement' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Aire Libre
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              <strong>
                Un lugar silencioso que se prepara para ser el hogar que siempre
                soñaste
              </strong>
              , calles arboladas, generosos jardínes y aire puro para volver a
              vivir con gusto los pequeños placeres de la vida: encender la
              estufa a leña en el invierno, y en el verano disfrutar el
              parrillero con una cena en familia, bajo las estrellas.
              <br></br>
              <br></br>
              <strong>
                Proyectar un futuro en la amplitud y pureza de un lugar pensado
                por ti, para ti y los tuyos.
              </strong>
            </p>
          </div>
          <img src={FotoroHornerosThree} alt='Barrio Polo' className='desktopElement' />
        </div>
        <div className='site-polo site-white'>
          <img src={FotoroHornerosTwo} alt='Barrio Polo' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
              Estilo
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              El hogar de tus sueños es posible. Una casa , hecha para ti de
              acuerdo con tus gustos, y las necesidades de tu vida y las de tu
              familia.
            </p>
            <p data-aos='fade-up' data-aos-duration='2500'>
              La armonioso distribución de los espacios, concebida en el diseño
              original del barrio, está pensada para distrutar de todos los
              encantos del entorno. Y, si se admite que el medio tiene una
              fuerte influencia en nuestro carácter, entonces la vida en este
              lugar promete el aire más apacible.
            </p>
          </div>
        </div>

        <div className='site-polo site-brown'>
          <img src={FotoroHornerosFour} alt='Barrio Polo' className='mobileElement' />
          <div>
            <h2 data-aos='fade-up' data-aos-duration='1500'>
            Valorización
            </h2>
            <p data-aos='fade-up' data-aos-duration='2500'>
              LOS OLIVOS se construye en un lugar privilegiado, en la zona con
              más proyección de crecimiento del este montevideano. Este proyecto
              nace como natural continuación de Carrasco, reviviendo el espíritu
              del lugar, elegante y plácido, que hace casi cien años nacía y se
              conocía como Balneario Carrasco.
            </p>
            <p data-aos='fade-up' data-aos-duration='2500'>
              Como aquellos emprendimientos pioneros de antaño, tu terreno
              tendrá la mayor valorización, y tu futura residencia, en el
              corazón del nuevo barrio, será una inversión segura, con una
              enorme proyección hacia el futuro.
            </p>
          </div>
          <img src={FotoroHornerosFour} alt='Barrio Polo' className='desktopElement' />
        </div>
      </section>

      <Frase frase="Disfrutá de la calma de este lugar" />
      <div id="faq" />
      <Information
        ImgInformation={OHornerosLight}
        queEsOlivos="Los Olivos de Los Horneros es un barrio privado que se está desarrollando en un predio de 72 hectáreas sobre Camino de los Horneros, Canelones, y que contará con 450 lotes para construir casas y 20 edificios. El mismo estará separado en 7 barrios diferentes, pero donde todos los futuros vecinos podrán hacer uso de todos los servicios que ofrece."
        queOfreceOlivos="Los servicios que ofrece se pueden separar en 2: los que se ofrecen para el funcionamiento de la casas y edificios y los que se ofrecen para las personas o familias."
        titleOfrece="Para las viviendas se ofrece:"
        ofreceUno="Agua potable"
        ofreceDos="Conexion a UTE (cableado subterráneo)"
        ofreceTres="Saneamiento"
        ofreceCuatro="Gas por cañería"
        ofreceCinco="Conexión a Antel (fibra óptica)"
        ofreceSeis="Agua para riego"
        ofreceSiete="Gestión de residuos"
        titleOfreceUno="Para las personas o familias se ofrece:"
        ofreceOcho="Club House con piscina frente al lago"
        ofreceNueve="Canchas de Tennis y de Paddle"
        ofreceDiez="Juegos para los niños"
        ofreceOnce="Locales comerciales"
        ofreceDoce="Oficinas"
        ofreceTrece="Hotel"
        ofreceCatorce="Seguridad física y electrónica"
        seguridadOlivos="Si, el barrio contará con seguridad privada. La seguridad contará con guardias físicos en todos los accesos y guardias que harán recorridas por el barrio y por el perímetro. Además, contará con un circuito cerrado de televisión y con barreras perimetrales."
        construccionOlivos=" Si, además de la normativa municipal que hay que cumplir, el barrio pone algunas normativas especificas que son para la mejora del barrio. Además, se pone como condiciones que los compradores deben cumplir con ciertas condiciones al igual que en Los Olivos Barrios del Polo, que son:"
        construccionUno="Terminar la casa antes de los 3 años de entregado el lote"
        construccionDos="Construir una superficie mayor a 150 m2"
        construccionTres="No construir propiedad horizontal"
        construccionCuatro="No construir una casa pre fabricada"
      />
      <div id="ubicacionPlano" />
      <div className='ratio ratio-16x9'>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/w9xpab8hvto'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
      <Map
        map=<GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDYJJGEsIbe3lEncnvtzddNuBvS_9vvCBk",
            language: "es",
          }}
          style={{ height: "100%", width: "100%" }}
          options={{ styles: DarkStyle.darkStyle, gestureHandling: "greedy" }}
          defaultZoom={15}
          center={{
            lat: -34.7802683084174,
            lng: -55.977457773012915,
          }}
        >
          <Marker lat={-34.7802683084174} lng={-55.977457773012915} />
        </GoogleMapReact>
      />
      <div id="contacto" />
      <Contact
        imgContact={BackgroundBarrioHornerosContact}
        logoContact={BackgroundLogoHornerosContact}
        selectLogoBarrio={LogoHorneros}
        barrio="Horneros"
      />
      <Frase frase="Un equipo que apunta a la excelencia siempre" />
      <div id="equipo" />
      {/* <Clients barrioId={2} /> */}
      <div className="equipoDesarrollo">
      <div>
          <img src={ClientFourHorneros} alt="logo" />
          <img src={ClientTwoHorneros} alt="logo" />
        </div>
        <div>
          <img src={ClientOneHorneros} alt="logo" />
          <img src={ClientThreeHorneros} alt="logo" />
        </div>
      </div>
      <Footer
        logoFooter={LogoHorneros}
        telefono="(+598) 2622 1333"
        direccion="Camino de los Horneros"
        email="info@losolivos.com"
        linkInstagram="https://www.instagram.com/losolivoshorneros/"
      />
    </div>
  );
}

export default BarrioHorneros;
