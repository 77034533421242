import { React, useState, useEffect } from "react";
import "../style/components/information.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Information(props) {
  const [openQuestionOne, setOpenQuestionOne] = useState(false);
  const [openQuestionTwo, setOpenQuestionTwo] = useState(false);
  const [openQuestionThree, setOpenQuestionThree] = useState(false);
  const [openQuestionFour, setOpenQuestionFour] = useState(false);

  const closeAll = () => {
    setOpenQuestionOne(false);
    setOpenQuestionTwo(false);
    setOpenQuestionThree(false);
    setOpenQuestionFour(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="background-container-information">
      <div className="container-information">
        <img
          src={props.ImgInformation}
          alt="Logo Olivos"
          className="desktopElement"
          data-aos="fade-right"
          data-aos-duration="1500"
        />
        <div
          className="container-questions"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <div className="question">
            <button
              type="button"
              onClick={() => {
                setOpenQuestionOne(!openQuestionOne);
              }}
              className={
                openQuestionOne === true
                  ? "buttonQuestionOpen"
                  : "buttonQuestionNoOpen"
              }
            >
              <div className="header-question">
                <div>
                  <h3 className="titleQuestion">¿Qué es Los Olivos?</h3>
                  <span className="more"></span>
                </div>
              </div>
              <div className="body-question"><p>{props.queEsOlivos}</p></div>
            </button>
          </div>

          <div className="question">
            <button
              type="button"
              onClick={() => {
                closeAll();
                setOpenQuestionTwo(!openQuestionTwo);
              }}
              className={
                openQuestionTwo === true
                  ? "buttonQuestionOpen"
                  : "buttonQuestionNoOpen"
              }
            >
              <div className="header-question">
                <div>
                  <h3 className="titleQuestion">
                    ¿Qué servicios ofrece Los Olivos?
                  </h3>
                  <span className="more"></span>
                </div>
              </div>
              <div className="body-question"><p>{props.queOfreceOlivos}</p> <p className="titleP">{props.titleOfrece}</p> <p>{props.ofreceUno}</p> <p>{props.ofreceDos}</p><p>{props.ofreceTres}</p><p>{props.ofreceCuatro}</p><p>{props.ofreceCinco}</p><p>{props.ofreceSeis}</p><p>{props.ofreceSiete}</p><p className="titleP">{props.titleOfreceUno}</p><p>{props.ofreceOcho}</p><p>{props.ofreceNueve}</p><p>{props.ofreceDiez}</p> <p>{props.ofreceOnce}</p><p>{props.ofreceDoce}</p><p>{props.ofreceTrece}</p><p>{props.ofreceCatorce}</p></div>
            </button>
          </div>

          <div className="question">
            <button
              type="button"
              onClick={() => {
                closeAll();
                setOpenQuestionThree(!openQuestionThree);
              }}
              className={
                openQuestionThree === true
                  ? "buttonQuestionOpen"
                  : "buttonQuestionNoOpen"
              }
            >
              <div className="header-question">
                <div>
                  <h3 className="titleQuestion">¿Tiene seguridad privada?</h3>
                  <span className="more"></span>
                </div>
              </div>
              <div className="body-question"><p>{props.seguridadOlivos}</p></div>
            </button>
          </div>

          <div className="question">
            <button
              type="button"
              onClick={() => {
                closeAll();
                setOpenQuestionFour(!openQuestionFour);
              }}
              className={
                openQuestionFour === true
                  ? "buttonQuestionOpen"
                  : "buttonQuestionNoOpen"
              }
            >
              <div className="header-question">
                <div>
                  <h3 className="titleQuestion">
                    ¿Hay obligaciones para la construcción?
                  </h3>
                  <span className="more"></span>
                </div>
              </div>
              <div className="body-question"><p>{props.construccionOlivos}</p><p>{props.construccionUno}</p><p>{props.construccionDos}</p><p>{props.construccionTres}</p><p>{props.construccionCuatro}</p></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
