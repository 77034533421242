import { React } from "react";
import "../style/components/footer.css";
import { Instagram } from "../img/img";

function Footer(props) {
  return (
    <footer>
      <div className="container-footer">
        <img src={props.logoFooter} alt="Logo" className="logo-footer" />
        <span>{props.telefono}</span>
        <span>{props.direccion}</span>
        <span>{props.email}</span>
        <div>
          <a href={props.linkInstagram} target="_blank" rel="noreferrer">
            <img src={Instagram} alt="Logo Instagram" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
