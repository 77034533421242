import { React, useState } from "react";
import "../style/components/map.css";
//import DarkStyle from './stylesMap';

function Map(props) {
  return (
    <div className="map">
    {props.map}
     {/* <div className="buttons-map">
        <button type="button" className="button-map">
          UBICACIÓN
        </button>
        <button type="button" className="button-map">
          DESCARGAR PLANO
        </button>
        <button type="button" className="button-map desktopElement">
          PLANO
        </button>
      </div> */}
    </div>
  );
}

export default Map;
