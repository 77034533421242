import { React, useEffect, useState } from "react";
import "../style/components/contact.css";
import { ArrowContact } from "../img/img";
import AOS from "aos";
import PopUp from "../components/Popup";
import "aos/dist/aos.css";
import { useForm } from "react-hook-form";
import { postContactMessage } from "../service/ContactMessageService";

function Contact(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  const [showPopup, setShowPopup] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const sendContactMessage = async (data) => {
    data.barrio = props.barrio;
    await postContactMessage(data);
    setShowPopup(true);
  };

  return (
    <>
      <div className="contact">
        <div className="img-contact desktopElement">
          <img
            src={props.imgContact}
            alt="Barrio los olvios"
            className="background-contact"
          />
        </div>
        <div className="logo-contact">
          <div
            className="container-form"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <h3>El hogar que siempre soñaste te está esperando.</h3>
            <p>¡Comunícate con nosotros!</p>
            <form onSubmit={handleSubmit(sendContactMessage)}>
              <div className="container-inputs">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nombre *"
                  {...register("name", { required: "Debe ingresar su nombre" })}
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email *"
                  {...register("email", { required: "Debe ingresar su email" })}
                />
                <textarea
                  type="message"
                  name="message"
                  id="message"
                  placeholder="Mensaje"
                  {...register("message", { required: "Debe ingresar un mensaje" })}
                />
              </div>
              {errors.name?.message && (
                <span className="form-error">{errors.name?.message}</span>
              )}
              {errors.email?.message && (
                <span className="form-error">{errors.email?.message}</span>
              )}
              <button type="submit" className="button-contact">
                Contactarme <img src={ArrowContact} alt="Icono de flecha" />
              </button>
            </form>
          </div>
          {showPopup && (
            <PopUp
              handleClose={setShowPopup}
              logoBarrio={props.selectLogoBarrio}
            />
          )}
          <img
            src={props.logoContact}
            alt="Logo los olvios"
            className="background-contact"
          />
        </div>
      </div>
    </>
  );
}

export default Contact;
