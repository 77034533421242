/* eslint-disable camelcase */
import axios from 'axios';
import endpoints from '../config/endpoint';

const postContactMessage = async (data) => {
  try {
    return (await axios.post(`${endpoints.contactMessages}`, data))
      .data;
  } catch (error) {
    return error;
  }
};

export { postContactMessage };
