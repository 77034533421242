/* eslint-disable camelcase */
import axios from 'axios';
import endpoints from '../config/endpoint';

const getBrandsForBarrio = async (barrioId) => {
  try {
    const params = {
      filter: { neighbourhoodId: barrioId },
    };
    return (await axios.get(`${endpoints.brands}`, { params }))
      .data;
  } catch (error) {
    return error;
  }
};

export { getBrandsForBarrio };
